import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Subject } from "rxjs";
import { PLTFieldErrors } from "../../Types";

// export const apiURL = "http://localhost:3001";
// export const apiURL = "https://dev.plt.api.innovaweb.cl";
export const apiURL = "https://plat.api.securityunion.cl";

export const graphQlEndpoint = `${apiURL}/graphql`;

const useClientGraphql = () => {
	const ErrorSubject = new Subject<PLTFieldErrors>();
	const httpLink = new HttpLink({
		uri: graphQlEndpoint
	});

	const errorLink = onError(({ graphQLErrors }) => {
		if (graphQLErrors) {
			console.log(graphQLErrors);
			const preError = graphQLErrors as unknown as Array<{ message: string }>;
			if (preError[0]) ErrorSubject.next(JSON.parse(preError[0].message));
		}
	});

	const client = new ApolloClient({
		cache: new InMemoryCache(),
		link: from([errorLink, httpLink])
	});

	return {
		client,
		ErrorSubject
	};
};

export default useClientGraphql;
